import AutoNumeric from 'autonumeric';

export default class AutoNumericUtil {
    public static find(e: HTMLElement) {
        return AutoNumeric.getAutoNumericElement(e);
    }

    public static findOrNew(e: HTMLElement, v?: string | number, options?: AutoNumeric.Options) {
        return AutoNumericUtil.find(e) || new AutoNumeric(e, v, options);
    }

    public static remove(e: HTMLElement) {
        AutoNumericUtil.find(e)?.remove();
    }

    public static set(e: HTMLElement, v: string | number, options?: AutoNumeric.Options, saveHistory?: boolean) {
        AutoNumericUtil.find(e).set(v, options, saveHistory);
    }

    public static get(e: HTMLElement) {
        return AutoNumericUtil.find(e).getNumber();
    }
}